import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Gender, Person, PersonFragmentFragment } from '@tx/api';
import { ContextMenuItem, Option, TxTab } from '@tx/ui';

type ServiceOwner = Pick<
  PersonFragmentFragment,
  'id' | 'firstName' | 'lastName' | 'email' | 'companyName' | 'phone' | 'mobilePhone' | 'gender'
>;

@Component({
  selector: 'tx-portal-service-owner-card',
  templateUrl: './service-owner-card.component.html'
})
export class ServiceOwnerCardComponent implements OnInit {
  constructor(private fb: FormBuilder) {}

  // Inputs
  @Input() subheading: string = '';
  @Input() serviceOwners$!: Observable<ServiceOwner[]>;
  @Input() public disabled!: boolean | null | undefined;
  @Input() people$!: Observable<Person[]>;

  // Outputs
  @Output() removeServiceOwner = new EventEmitter<string>();
  @Output() addServiceOwner = new EventEmitter<string>();
  @Output() createServiceOwner = new EventEmitter<Person>();

  // Variables
  genderOptions: Array<string> = ['Männlich', 'Weiblich', 'Divers'];
  selectedPerson: Option | null = null;
  newPerson: Person = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    mobilePhone: '',
    gender: null
  } as Person;
  editOwnerModal = false;
  addOwnerModal = false;

  // Context Menu
  contextMenu: Array<ContextMenuItem[]> = [
    [
      { label: 'Detail', icon: 'document-text', event: 'detail' },
      { label: 'Entfernen', icon: 'v-trash', event: 'delete', isDanger: true }
    ]
  ];
  contextMenuClick(event: string, serviceOwner: ServiceOwner) {
    switch (event) {
      case 'detail':
        this.fillDetailView(serviceOwner);
        this.editOwnerModal = true;
        return;
      case 'delete':
        this.removeServiceOwner.emit(serviceOwner.id);
        return;
      default:
        break;
    }
  }

  // Tabs
  tabs: TxTab[] = [
    { value: 'Bestehenden Account', icon: 'user' },
    { value: 'Neuen Account erstellen', icon: 'plus' }
  ];
  modalActiveTab = this.tabs[0].value;
  switchTab(tab: string) {
    this.modalActiveTab = tab;
  }

  // Observerables
  peopleOptions$!: Observable<Option[]>;

  // Forms
  newPersonForm = this.fb.group({
    firstName: [''],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]]
  });

  detailPersonForm = this.fb.group({
    id: [''],
    firstName: [''],
    lastName: [''],
    gender: [''],
    companyName: [''],
    phone: [''],
    mobilePhone: [''],
    email: ['']
  });

  // Functions
  selectPerson(person: Option) {
    this.selectedPerson = person;
  }

  saveAndClose() {
    if (this.modalActiveTab === this.tabs[0].value && this.selectedPerson) {
      this.addServiceOwner.emit(this.selectedPerson.value);
    } else {
      this.createServiceOwner.emit(this.newPerson);
    }
    this.addOwnerModal = false;
  }

  getGenderEnum(genderString: string): Gender | undefined {
    switch (genderString) {
      case 'Männlich':
        return Gender.M;
      case 'Weiblich':
        return Gender.W;
      case 'Divers':
        return Gender.D;
      default:
        return undefined;
    }
  }

  getGenderString(genderEnum: Maybe<Gender> | undefined): string {
    switch (genderEnum) {
      case Gender.M:
        return 'Männlich';
      case Gender.W:
        return 'Weiblich';
      case Gender.D:
        return 'Divers';
      default:
        return '';
    }
  }

  fillDetailView(serviceOwner: ServiceOwner) {
    this.detailPersonForm.setValue({
      id: serviceOwner.id,
      firstName: serviceOwner.firstName ? serviceOwner.firstName : '',
      lastName: serviceOwner.lastName ? serviceOwner.lastName : '',
      gender: this.getGenderString(serviceOwner.gender),
      companyName: serviceOwner.companyName ? serviceOwner.companyName : '',
      phone: serviceOwner.phone ? serviceOwner.phone : '',
      mobilePhone: serviceOwner.mobilePhone ? serviceOwner.mobilePhone : '',
      email: serviceOwner.email ? serviceOwner.email : ''
    });
  }

  // setup
  ngOnInit(): void {
    this.detailPersonForm.disable();
    this.peopleOptions$ = this.serviceOwners$.pipe(
      switchMap((serviceOwners) => {
        const sInstanceIds = serviceOwners?.map((so) => so.id);
        return this.people$.pipe(
          map((people) => {
            return people.filter((p) => !sInstanceIds?.includes(p.id));
          }),
          map((p) =>
            p.map((p2) => {
              return {
                value: p2.id,
                label: `${p2.email}`,
                description: `${p2.firstName} ${p2.lastName}`
              };
            })
          )
        );
      })
    );
    this.newPersonForm.valueChanges.subscribe((form) => {
      this.newPerson = {
        firstName: form.firstName ? form.firstName : '',
        lastName: form.lastName ? form.lastName : '',
        email: form.email ? form.email : ''
      } as Person;
    });
  }
}
