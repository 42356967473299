<div ui-card>
  <ui-modal
    [isModalOpen]="addOwnerModal"
    [title]="'Service Owner hinzufügen'"
    [submitLabel]="'Speichern & Hinzufügen'"
    (onSubmit)="saveAndClose()"
    (onClose)="addOwnerModal = false; selectedPerson = null"
  >
    <div class="flex w-full justify-center mb-8">
      <div class="bg-gray-100 p-1 rounded-2xl">
        <ui-tabs
          *ngIf="tabs"
          [objTabs]="tabs"
          (activeTabChange)="switchTab($event)"
          [activeTab]="modalActiveTab"
        ></ui-tabs>
      </div>
    </div>

    <ng-container *ngIf="modalActiveTab === this.tabs[0].value">
      <ui-forms-combobox
        *ngIf="peopleOptions$ | async as _options"
        [selectedValue]="selectedPerson"
        name="users"
        label="Bestehende Person hinzufügen"
        [initalValue]="false"
        [options]="_options"
        (cchange)="selectPerson($event)"
      ></ui-forms-combobox>
    </ng-container>

    <ng-container *ngIf="modalActiveTab === this.tabs[1].value">
      <ui-info class="mb-6">
        <ui-icon [icon]="'information-circle'" class="w-5 h-5 shrink-0"></ui-icon>
        <p>
          Die Person wird als neuer TelemaxX Kundencenter-Benutzer eingeladen und erhält die zugewiesene Rolle. Achten
          Sie bitte auf die korrekte E-Mail Adresse.
        </p>
      </ui-info>
      <ui-form-wrapper [parentForm]="newPersonForm">
        <div class="grid grid-cols-2 gap-4">
          <ui-forms-input
            label="Vorname"
            icon="user"
            [controlName]="'firstName'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
          <ui-forms-input
            label="Nachname"
            icon="user"
            [controlName]="'lastName'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
          <ui-forms-input
            class="col-span-2"
            label="E-Mail"
            icon="envelope"
            [type]="'email'"
            [controlName]="'email'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
        </div>
      </ui-form-wrapper>
    </ng-container>
  </ui-modal>
  <ui-modal
    [isModalOpen]="editOwnerModal"
    [showButtons]="false"
    [title]="'Service Owner'"
    (onClose)="editOwnerModal = false; this.detailPersonForm.reset(); selectedPerson = null"
  >
    <ui-form-wrapper [parentForm]="detailPersonForm">
      <div class="grid grid-cols-2 gap-4">
        <ui-forms-input
          label="Vorname"
          icon="user"
          [controlName]="'firstName'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Nachname"
          icon="user"
          [controlName]="'lastName'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
        <ui-forms-select
          label="Geschlecht"
          name="gender"
          [placeholder]="'Geschlecht wählen'"
          [options]="genderOptions"
          [controlName]="'gender'"
          [parentForm]="detailPersonForm"
        ></ui-forms-select>
        <ui-forms-input
          label="Firmenname"
          icon="cloud"
          [controlName]="'companyName'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Telefon"
          icon="phone"
          [controlName]="'phone'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Mobile"
          icon="phone"
          [controlName]="'mobilePhone'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          class="col-span-2"
          label="E-Mail"
          icon="envelope"
          [type]="'email'"
          [controlName]="'email'"
          [parentForm]="detailPersonForm"
        ></ui-forms-input>
      </div>
    </ui-form-wrapper>
  </ui-modal>

  <ui-headline
    [heading]="'Service Owner'"
    subheading="Diese Nutzer sind Service-Administrator"
    [size]="'lg'"
    class="mb-8"
  ></ui-headline>
  <div class="flex flex-col gap-y-4">
    <tx-portal-user-card
      *ngFor="let person of serviceOwners$ | async"
      [user]="person"
      [disabled]="!!disabled"
      [contextMenu]="contextMenu"
      (contextMenuClick)="contextMenuClick($event, person)"
    ></tx-portal-user-card>

    <span class="block w-full text-sm text-center" *ngIf="(serviceOwners$ | async)?.length === 0"
      >Noch kein Service Owner konfiguriert. <br />
      <a
        target="_blank"
        class="leading-6 text-goblin-500 hover:underline"
        href="https://kb.telemaxx.de/docs/kundencenter-benutzer-verwalten#howto-service-owner-hinzuf%C3%BCgenentfernen---am-service"
      >
        Hilfe/Anleitung öffnen
      </a>
    </span>

    <hr />

    <div *ngIf="peopleOptions$ | async as options">
      <ui-button
        color="gray"
        (cclick)="addOwnerModal = true"
        [isDisabled]="!!disabled"
        icon="plus-small"
        [isFullWidth]="true"
      >
        Service Owner hinzufügen
      </ui-button>
    </div>
  </div>
</div>
