import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@tx/ui';
import { ProfileComponent } from './profile/profile.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../token.interceptor';
import { RegisterComponent } from './register.component';
import { TfaComponent } from './tfa/tfa.component';
import { PartyRoutingModule } from './party-routing.module';
import { SessionsCardComponent } from './sessions-card/sessions-card.component';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { de } from 'date-fns/locale';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { SharedModule } from '../shared/shared.module';

const deConfig = new DateFnsConfigurationService();
deConfig.setLocale(de);

@NgModule({
  declarations: [ProfileComponent, RegisterComponent, TfaComponent, SessionsCardComponent, ProfileCardComponent],
  imports: [CommonModule, HttpClientModule, UiModule, PartyRoutingModule, DateFnsModule.forRoot(), SharedModule],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: DateFnsConfigurationService, useValue: deConfig }
  ]
})
export class PartyModule {}
