<ui-modal
  [isModalOpen]="isServiceUserModalOpen"
  [title]="!detailUserID ? 'Service User hinzufügen' : 'Userdetails'"
  [submitLabel]="!detailUserID ? 'Speichern & Hinzufügen' : 'Speichern'"
  (onClose)="closeServiceUserModal()"
  (onSubmit)="submitServiceUser()"
  [isCloseButtonVisible]="false"
>
  <div class="flex w-full justify-center my-8" *ngIf="!detailUserID">
    <div class="bg-gray-100 p-1 rounded-2xl">
      <ui-tabs
        *ngIf="tabs"
        [objTabs]="tabs"
        (activeTabChange)="this.modalActiveTab = $event"
        [activeTab]="modalActiveTab"
      ></ui-tabs>
    </div>
  </div>

  <ng-container *ngIf="modalActiveTab === tabs[0].value">
    <ui-forms-combobox
      *ngIf="peopleOptions$ | async as _people"
      name="users"
      label="Bestehende Person hinzufügen"
      [initalValue]="false"
      [options]="_people"
      [selectedValue]="selectedPerson"
      (cchange)="selectPerson($event)"
    >
    </ui-forms-combobox>
  </ng-container>
  <ng-container *ngIf="modalActiveTab === tabs[1].value">
    <ui-info class="mb-6" *ngIf="!detailUserID">
      <ui-icon [icon]="'information-circle'" class="w-5 h-5 shrink-0"></ui-icon>
      <p>
        Die Person wird als neuer TelemaxX Kundencenter-Benutzer eingeladen und erhält die zugewiesene Rolle. Achten Sie
        bitte auf die korrekte E-Mail Adresse.
      </p>
    </ui-info>
    <ui-form-wrapper [parentForm]="serviceUserForm">
      <div class="grid grid-cols-2 gap-4">
        <ui-forms-input
          label="Vorname"
          icon="user"
          [controlName]="'firstName'"
          [controlGroup]="'person'"
          [readonly]="!!detailUserID"
          [parentForm]="serviceUserForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Nachname"
          icon="user"
          [controlName]="'lastName'"
          [controlGroup]="'person'"
          [readonly]="!!detailUserID"
          [parentForm]="serviceUserForm"
        ></ui-forms-input>
        <ui-forms-input
          class="col-span-2"
          label="E-Mail"
          icon="envelope"
          [type]="'email'"
          [controlName]="'email'"
          [readonly]="!!detailUserID"
          [controlGroup]="'person'"
          [parentForm]="serviceUserForm"
        ></ui-forms-input>
      </div>
    </ui-form-wrapper>
  </ng-container>
  <ui-forms-select
    class="mt-4"
    label="Rolle"
    name="role"
    [placeholder]="'Rolle wählen'"
    [options]="roleOptions"
    [controlName]="'role'"
    [parentForm]="serviceUserForm"
  ></ui-forms-select>

  <!-- <div class="flex items-start relative mt-4">
    <div class="group">
      <div class="text-sm font-medium flex items-center gap-1 text-blue-500">
        <ui-icon [icon]="'question-mark-circle'" class="h-5 w-5"></ui-icon>
        <span>Was bedeutet die Rolle?</span>
      </div>
      <div class="absolute left-0 bottom-0 pb-7 hidden z-50 group-hover:block w-full">
        <ui-info class="ring-2 ring-blue-500 ring-offset-0 rounded-xl">
          <div class="flex flex-col">
            <p>
              <span class="font-medium">Rolle Cloud User: Standard-Rolle<br /></span>
              Ein Benutzer mit der Rolle Cloud-User hat Zugriff:
            </p>
            <ul class="list-disc pl-4">
              <li>auf die Projekte, denen die Gruppe "All-Users-..." zugewiesen ist</li>
              <li>auf die Projekte (VMs & Templates), denen er als User explizit zugewiesen ist</li>
            </ul>
            <p>
              Alle Benutzer mit der Rolle Cloud-User werden automatisch in die Gruppe "All-Users-..." zusammengefasst.
              Nutzer mit dieser Rolle haben keinen Zugriff auf die Projekt-Management Funktionen.<br />
              <br />
              <span class="font-medium">Rolle Cloud Manager: Erweiterte Rolle<br /></span>
              Ein Benutzer mit der Rolle Cloud-Manager hat Zugriff:
            </p>
            <ul class="list-disc pl-4">
              <li>auf alle Projekte (VMs & Templates)</li>
              <li>kann alle verfügbare Aktionen auf VMs ausführen</li>
              <li>zusätzlich Zugriff auf die Projekt-Management Funktionen</li>
            </ul>
            <p>
              Alle Benutzer mit der Rolle Cloud-User werden automatisch in die Gruppe "All-Managers-..."
              zusammengefasst.
            </p>
            <a class="underline" target="_blank" href="https://kb.telemaxx.de/docs/cloud-user-roles-projects"
              >Mehr erfahren</a
            >
          </div>
        </ui-info>
      </div>
    </div>
  </div> -->
</ui-modal>

<ui-headline heading="Service User & Access" subheading="Diese Nutzer haben Zugang zum Service" size="lg"></ui-headline>

<div class="flex flex-col mt-4 gap-4">
  <tx-portal-user-card
    *ngFor="let user of project.serviceUsers"
    [user]="user.person"
    [contextMenu]="contextMenu"
    (contextMenuClick)="contextMenuClick($event, user)"
  >
    <ng-container *ngIf="user.role">
      <ui-tag [label]="'Gruppe'">{{ user.role }}</ui-tag>
    </ng-container>
  </tx-portal-user-card>

  <div class="w-full text-sm text-center my-2" *ngIf="project.serviceUsers?.length === 0">
    Noch keine Service User konfiguriert.<br />
    <a
      target="_blank"
      class="text-goblin-500 hover:underline"
      href="https://kb.telemaxx.de/docs/kundencenter-benutzer-verwalten#howto-service-user-hinzuf%C3%BCgenentfernen---am-service"
    >
      Hilfe/Anleitung öffnen
    </a>
  </div>
  <ui-button color="gray" (cclick)="openServiceUserModal()" icon="user-plus" [isFullWidth]="true">
    Service User hinzufügen
  </ui-button>
</div>
