import colors from './colors';

const environmentDevNew = {
  production: false,
  apiBasePath: 'https://my.dev.kdc-k8s.netzreich.cloud/api',
  graphqlEndpoint: 'https://my.dev.kdc-k8s.netzreich.cloud/api/graphql',
  graphqlEndpointWs: 'wss:///my.dev.kdc-k8s.netzreich.cloud/api/graphql',
  services: {
    s3: {
      defaultConfig: {
        endpoint: 'https://s3-stage.telemaxx.cloud',
        region: 'default',
        tls: true
      }
    }
  },
  oidc: {
    clientId: 'account',

    issuer: 'https://auth-dev.telemaxx.de/realms/TelemaxX'
  },
  colors
};
export const environment = environmentDevNew;
