import { Inject, Injectable } from '@angular/core';
import {
  CreateOpencloudProjectInput,
  CreateServiceInstanceInput,
  InlineServiceInstance,
  OpencloudProjectSortFields,
  OpencloudProjectsQuery,
  ProjectSetupInput,
  ServiceInstance,
  ServiceUserRole,
  SortDirection,
  TxApi,
  UpdateOpencloudProjectInput
} from '@tx/api';
import { NotificationService } from '@tx/ui';
import { BaseService, InlineFormGroup, NxFormGroup, SimpleFormControls } from '@tx/shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as uuid from 'uuid';
import { PartyService } from '../../party/party.service';

export type OpencloudProjectType = OpencloudProjectsQuery['opencloudProjects']['nodes'][0];

@Injectable({
  providedIn: 'root'
})
export class OpencloudProjectService extends BaseService<OpencloudProjectType[], OpencloudProjectService, TxApi> {
  protected override DEFAULT_SORT_FIELD: string = OpencloudProjectSortFields.Created;
  protected override DEFAULT_SORT_DIRECTION: 'Asc' | 'Desc' = 'Asc';

  queryFunction = this.txApi.opencloudProjectsWatch.bind(this.txApi);
  queryOneFunction = undefined;
  updateOneFunction = this.txApi.updateOneOpencloudProject.bind(this.txApi);
  deleteOneFunction = this.txApi.deleteOneOpencloudProject.bind(this.txApi);
  createOneFunction = this.txApi.createOneOpencloudProject.bind(this.txApi);

  override sortableFields: string[] = ['name'];

  updateManyFunction = undefined;

  constructor(
    txApi: TxApi,
    private fb: FormBuilder,
    route: ActivatedRoute,
    notificationService: NotificationService,
    private apollo: Apollo,
    private http: HttpClient,
    @Inject('env') private env: any,
    private partyService: PartyService
  ) {
    super(txApi, fb, route, notificationService);
    this.DEFAULT_SORT_FIELD = 'name';
  }

  async buyOpencloud(context: Record<string, any>) {
    return firstValueFrom(
      this.http.post<any>(`${this.env.apiBasePath}/opencloud/buy`, {
        setupBlank: context['setupBlank'],
        acceptPricelist: context['acceptPricelist'],
        acceptDescription: context['acceptDescription'],
        acceptPolicy: context['acceptPolicy'],
        orgId: context['orgId']
      })
    );
  }

  mapQueryData(data: OpencloudProjectsQuery) {
    return data?.opencloudProjects.nodes;
  }

  deleteServiceUser(id: string) {
    this.txApi.deleteOneServiceUser({ input: { id } }).subscribe((data) => {
      // Tell Apollo cache that ServiceUser was deleted
      const cache = this.apollo.client.cache;
      cache.evict({ id: `ServiceUser:${id}` });
    });
  }

  update() {
    this.updateOne(this.updateFormGroup.value, {
      successMessage: 'Das Projekt wurde erfolgreich aktualisiert',
      errorMessage: 'Projekt konnte nicht aktualisiert werden'
    }).subscribe();
  }

  create(data: CreateOpencloudProjectInput) {
    return this.partyService.person$.pipe(
      switchMap((person) => {
        return this.createOne(
          { opencloudProject: data },
          {
            successMessage: 'Das Projekt wurde erfolgreich erstellt',
            errorMessage: 'Projekt konnte nicht erstellt werden'
          }
        ).pipe(
          switchMap((project) => {
            const serviceUser = {
              id: uuid.v4(),
              person: {
                id: person.id,
                email: person.email,
                firstName: person.firstName,
                lastName: person.lastName
              },
              project: {
                id: project.data?.createOneOpencloudProject.id
              },
              role: ServiceUserRole.Member
            };
            return this.updateOne({
              id: project.data?.createOneOpencloudProject.id,
              update: {
                serviceUsers: [serviceUser]
              }
            });
          })
        );
      })
    );
  }

  delete(projectID: string) {
    this.deleteOne(projectID, '', {
      successMessage: 'Das Projekt wurde erfolgreich gelöscht',
      errorMessage: 'Projekt konnte nicht gelöscht werden'
    }).subscribe();
  }
}
