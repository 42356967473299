import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Company, Gender, Maybe, Person } from '@tx/api';
import { map, Observable, switchMap } from 'rxjs';
import { DcamService } from '../../dcam/dcam.service';

// dublicate type to prevent circular build dependency
type OtpCredential = {
  type: 'otp' | 'password' | 'none';
  category: 'basic-authentication' | 'two-factor';
  createAction: 'CONFIGURE_TOTP' | 'UPDATE_PASSWORD';
  removeable: boolean;
  userCredentialMetadatas: any[];
};
export interface updateNotifcationSettingsPayload {
  technicalUpdateNotifications: boolean;
  newsletter: boolean;
}

@Component({
  selector: 'tx-portal-profile-card',
  templateUrl: './profile-card.component.html'
})
export class ProfileCardComponent implements OnInit {
  @Input() currentTenant$!: Observable<Company | undefined>;

  constructor(private fb: NonNullableFormBuilder) {}

  genderOptions: string[] = ['Männlich', 'Weiblich', 'Divers'];

  tfa: boolean = false;

  notificationSettingsForm = this.fb.group({
    technicalAccountNotifications: [true],
    technicalUpdateNotifications: [false],
    newsletter: [false]
  });

  profileForm = this.fb.group({
    id: [''],
    email: this.fb.control({ value: '', disabled: true }),
    firstName: [''],
    lastName: [''],
    gender: [''],
    companyName: [''],
    mobilePhone: [''],
    phone: ['']
  });

  @Input()
  person$!: Observable<Person>;

  @Input()
  otpCredentials$!: Observable<OtpCredential[]>;

  @Input()
  session$!: Observable<any>;

  billingRoles$!: Observable<string[]>;

  // _person!: PeopleQuery['people']['edges'][0]['node'];

  @Output()
  updateNotifcationSettings = new EventEmitter<updateNotifcationSettingsPayload>();

  @Output()
  saveProfile = new EventEmitter<Person>();

  @Output()
  resetPassword = new EventEmitter<any>();

  @Output()
  configureTotp = new EventEmitter<any>();

  saveProfileWrapper() {
    if (!this.profileForm.valid) return;
    let person: Person = {
      id: this.profileForm.controls['id'].value,
      email: this.profileForm.controls['email'].value,
      firstName: this.profileForm.controls['firstName'].value,
      lastName: this.profileForm.controls['lastName'].value,
      gender: this.getGenderEnum(this.profileForm.controls['gender'].value),
      companyName: this.profileForm.controls['companyName'].value,
      mobilePhone: this.profileForm.controls['mobilePhone'].value,
      phone: this.profileForm.controls['phone'].value
    } as Person;
    this.saveProfile.emit(person);
  }

  getGenderEnum(gender: string | null | undefined): Gender | undefined {
    switch (gender) {
      case 'Männlich':
        return Gender.M;
      case 'Weiblich':
        return Gender.W;
      case 'Divers':
        return Gender.D;
      default:
        return undefined;
    }
  }

  getGenderString(gender: Maybe<Gender> | undefined): string {
    switch (gender) {
      case Gender.M:
        return 'Männlich';
      case Gender.W:
        return 'Weiblich';
      case Gender.D:
        return 'Divers';
      default:
        return '';
    }
  }

  ngOnInit() {
    this.billingRoles$ = this.session$.pipe(
      map((session) => {
        const roles = session.fulltenants
          .filter((role: string) => {
            return role.includes('Billing');
          })
          .map((role: string) => {
            return role.split('/')[2];
          });

        return [...new Set(roles)] as string[];
      })
    );
    // fill in forms
    this.person$.subscribe((person) => {
      this.notificationSettingsForm.setValue(
        {
          technicalAccountNotifications: !!person.technicalAccountNotifications,
          technicalUpdateNotifications: !!person.technicalUpdateNotifications,
          newsletter: !!person.newsletter
        },
        {
          emitEvent: false
        }
      );
      this.profileForm.setValue(
        {
          id: person.id,
          email: person.email,
          firstName: person.firstName ? person.firstName : '',
          lastName: person.lastName ? person.lastName : '',
          gender: this.getGenderString(person.gender),
          companyName: person.companyName ? person.companyName : '',
          mobilePhone: person.mobilePhone ? person.mobilePhone : '',
          phone: person.phone ? person.phone : ''
        },
        {
          emitEvent: false
        }
      );
    });
    // update on form change
    this.notificationSettingsForm.valueChanges.subscribe((form) => {
      let payload: updateNotifcationSettingsPayload = {
        //technicalAccountNotifications: !!form.technicalAccountNotifications,
        technicalUpdateNotifications: !!form.technicalUpdateNotifications,
        newsletter: !!form.newsletter
      };
      this.saveProfile.emit({
        id: this.profileForm.controls['id'].value,
        ...payload
      } as Person);
    });
  }
}
